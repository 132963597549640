/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { FC } from "react"
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  Create,
  Datagrid,
  Edit,
  List,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin"

import MediaWithUpload from "./components/MediaWithUpload"
import { SearchFilter } from "./utils"

export const ScheduleCurationCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput reference="Community" source="communityId">
        <AutocompleteInput source="code" />
      </ReferenceInput>
      <TextInput source="name" />
      <TextInput multiline source="description" />
      <MediaWithUpload
        filename={props["id"] as string}
        label="Media"
        path="module/schedule-curation"
      />
    </SimpleForm>
  </Create>
)

export const ScheduleCurationEdit: FC<any> = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <ReferenceInput reference="Community" source="communityId">
        <AutocompleteInput source="code" />
      </ReferenceInput>
      <TextInput source="name" />
      <TextInput multiline source="description" />
      <MediaWithUpload
        filename={props["id"] as string}
        label="Media"
        path="module/schedule-curation"
      />
      <ReferenceArrayInput reference="CatalogItemSchedule" source="includedItems">
        <AutocompleteArrayInput source="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Edit>
)

export const ScheduleCurationList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="edit">
      <ReferenceField reference="Community" source="communityId">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <TextField source="description" />
    </Datagrid>
  </List>
)
